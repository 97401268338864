.server-error-page {
  background-image: url('../images/pipes/shocked.svg');
  background-size: 50% 100%;
  background-position: 100% 0;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  height: 50vh;
}

.server-error-page > * {
  width: 45%;
}

.server-error-page p {
  line-height: 1.4;
}
